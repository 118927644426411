import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";

import { Button } from "@material-ui/core";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import storage from "services/storage";
import {
  USER_MANAGEMENT_ROLE,
  USER_ROLE,
  GENERAL_ADMIN_ROLE,
  PROCEDURE_AREA_ADMIN,
  EXPEDIENTE_VIEWER_ROLE,
  SUBSECRETARY_HEAD,
  SECRETARY_HEAD,
  RECORD_ADMINISTRATOR_ROLE,
  RECORD_VALIDATOR_ROLE,
  RECORD_TRACKING_LINK,
  MANAGER_ROLE,
  RESPONSIBLE_ROLE,
  GENERAL_DIRECTION_HEAD,
  HEAD_OF_DEPARTMENT,
  PUBLIC_SERVER_OPERATIONAL,
  UNIT_HOLDER,
  OPERATIVE_PROCEDURE_RESPONSIBLE_ROLE,
  REGULATORY_VALIDATOR,
} from "constants/Roles";
import Directory from "components/DeskHelp";

import "../../styles/custom_styles.scss";

class SidenavContent extends Component {
  constructor() {
    super();
    const data = storage.getData();
    this.state = {
      iconColaborationBlog: "SECADMON_Blog_de_colaboración_Inactive",
      user: data.user,
    };
  }

  componentDidMount() {
    const that = this;

    const menuLi = document.getElementsByClassName("menu");

    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        const parentLiEle = that.closest(this, "li");
        if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains("open")) {
            menuLi[i].classList.remove("open", "active");
          } else {
            menuLi[i].classList.add("open", "active");
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, "li");
            if (
              menuLi[j] !== this &&
              (parentLi === null || !parentLi.classList.contains("open"))
            ) {
              menuLi[j].classList.remove("open");
            } else {
              if (menuLi[j].classList.contains("open")) {
                menuLi[j].classList.remove("open");
              } else {
                menuLi[j].classList.add("open");
              }
            }
          }
        }
      };
    }
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) { }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function (fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) { }

    return null;
  }

  goToView = (path) => {
    const currentPath = this.props?.history?.location?.pathname || "";
    if (currentPath !== path) {
      this.props.history.push(path);
    }
  };

  render() {
    const { user } = this.state;
    const locationPath =  window.location.pathname.split("/")[2]
    const profile = locationPath === "profile";
    const users = locationPath === "users";
    const procedures = locationPath === "procedures";
    const surveys = locationPath === "surveys";
    const admin = locationPath === "users"||locationPath ==="help-content"|| locationPath ==="day-absence"||locationPath ==="administration"
    const roles = user?.rol ? user.rol.split(", ") : [];
    const presentFollowToProcedureOption =
      !user?.rol?.includes(USER_ROLE) ||
      user?.rol?.includes(PROCEDURE_AREA_ADMIN) ||
      user?.rol?.includes(MANAGER_ROLE) ||
      user?.rol?.includes("Enlace de seguimiento a trámite") ||
      user?.rol?.includes(SUBSECRETARY_HEAD) ||
      user?.rol?.includes(RESPONSIBLE_ROLE) ||
      user?.rol?.includes(OPERATIVE_PROCEDURE_RESPONSIBLE_ROLE) ||
      user?.rol?.includes(GENERAL_DIRECTION_HEAD) ||
      user?.rol?.includes(PUBLIC_SERVER_OPERATIONAL) ||
      user?.rol?.includes(HEAD_OF_DEPARTMENT) ||
      user?.rol?.includes(SECRETARY_HEAD);

    const presentRecordValidation =
      user?.rol?.includes(RECORD_VALIDATOR_ROLE) ||
      user?.rol?.includes(RECORD_ADMINISTRATOR_ROLE);
    const presentRequestsHistorical =
      user?.rol?.includes(USER_MANAGEMENT_ROLE) ||
      user?.rol?.includes(RECORD_ADMINISTRATOR_ROLE);
    const presentAdminForRecords = user?.rol?.includes(
      RECORD_ADMINISTRATOR_ROLE
    );
    const presentUserOptions =
      this.state.user?.rol?.includes(USER_MANAGEMENT_ROLE) ||
      presentAdminForRecords;
    const presentRecordsVisualization =
      user?.rol?.includes(EXPEDIENTE_VIEWER_ROLE) ||
      user?.rol?.includes(RECORD_ADMINISTRATOR_ROLE);
    const presentRecordRequestsTracking =
      user?.rol?.includes(RECORD_ADMINISTRATOR_ROLE) ||
      user?.rol?.includes(RECORD_VALIDATOR_ROLE) ||
      user?.rol?.includes(RECORD_TRACKING_LINK);
    const presentProceduresInMyCharge =
      user?.rol?.includes(RESPONSIBLE_ROLE) ||
      user?.rol?.includes(PROCEDURE_AREA_ADMIN) ||
      user?.rol?.includes(MANAGER_ROLE) ||
      user?.rol?.includes(SECRETARY_HEAD) ||
      user?.rol?.includes(SUBSECRETARY_HEAD) ||
      user?.rol?.includes(GENERAL_DIRECTION_HEAD) ||
      user?.rol?.includes(HEAD_OF_DEPARTMENT) ||
      user?.rol?.includes(PUBLIC_SERVER_OPERATIONAL) ||
      user?.rol?.includes(USER_MANAGEMENT_ROLE) ||
      user?.rol?.includes(REGULATORY_VALIDATOR) ||
      user?.rol?.includes(OPERATIVE_PROCEDURE_RESPONSIBLE_ROLE);
    const level4Profiles =
      user?.rol?.includes(SUBSECRETARY_HEAD) ||
      user?.rol?.includes(SECRETARY_HEAD) ||
      user?.rol?.includes(GENERAL_DIRECTION_HEAD) ||
      user?.rol?.includes(PROCEDURE_AREA_ADMIN);
    const procedureUnitHolder = user?.rol?.includes(UNIT_HOLDER);
    const presentAdminOptions =
      user?.rol?.includes(USER_MANAGEMENT_ROLE);

    return (
      <CustomScrollbars className=" scrollbar" style={{ overflow: "hidden" }}>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ flex: "0 0 100%", maxWidth: "100%" }}>
            <ul className="nav-menu">
              <li className="nav-header">
                <IntlMessages id="sidebar.main" />
              </li>

              <li className="menu no-arrow">
                <NavLink
                  className="prepend-icon sidenav__Option"
                  to="/app/blog"
                >
                  <i className="zmdi icon-SEJ_Inicio zmdi-hc-fw" />
                  <span className="nav-text customColor">Mi red social</span>
                </NavLink>
              </li>

              <li
                className={`ui_tooltip menu sidenav__Multi ${profile ? "selected open" : ""
                  }`}
              >
                <Button onClick={() => this.goToView("/app/profile")}>
                  <i className="zmdi icon-SEJ_Mi_expediente zmdi-hc-fw" />
                  <span className="nav-text customColor">Mi expediente</span>
                </Button>

                <ul className="sub-menu">
                  <li>
                    <NavLink
                      className="prepend-icon"
                      activeClassName="selected"
                      to="/app/profile/electronic-record"
                    >
                      <span className="nav-text">Mis documentos</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="prepend-icon"
                      activeClassName="selected"
                      to="/app/profile/my-records"
                    >
                      <span className="nav-text">Mis solicitudes</span>
                    </NavLink>
                  </li>
                  {presentRecordValidation && (
                    <li>
                      <NavLink
                        className="prepend-icon"
                        activeClassName={window.location.pathname.split("/").length == 4 ? "selected" : ""}
                        to="/app/profile/records-validation"
                      >
                        <span className="nav-text">Solicitudes a mi cargo</span>
                      </NavLink>
                    </li>
                  )}
                  {presentRequestsHistorical && (
                    <li>
                      <NavLink
                        className="prepend-icon"
                        activeClassName="selected"
                        to="/app/profile/records-validation/historical"
                      >
                        <span className="nav-text">
                          Historial de solicitudes
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {presentRecordsVisualization && (
                    <li>
                      <NavLink
                        className="prepend-icon"
                        activeClassName="selected"
                        to="/app/profile/records"
                      >
                        <span className="nav-text">
                          Visualización de expedientes
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {presentRecordRequestsTracking && (
                    <li>
                      <NavLink
                        className="prepend-icon"
                        activeClassName="selected"
                        to="/app/profile/tracking"
                      >
                        <span className="nav-text">Sala de seguimiento</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </li>

              <li
                className={`ui_tooltip menu sidenav__Multi ${procedures ? "selected open" : ""
                  }`}
              >
                <Button onClick={() => this.goToView("/app/procedures")}>
                  <i className="zmdi icon-SEJ_Reportes zmdi-hc-fw" />
                  <span className="nav-text customColor">Mis trámites</span>
                </Button>

                <ul className="sub-menu">
                  <li>
                    <NavLink
                      className="prepend-icon"
                      activeClassName="selected"
                      to="/app/procedures/my-procedures"
                    >
                      <span className="nav-text">Mis solicitudes</span>
                    </NavLink>
                  </li>
                  {presentProceduresInMyCharge && (
                    <li>
                      <NavLink
                        className="prepend-icon"
                        activeClassName="selected"
                        to="/app/procedures/procedures-in-my-charge"
                      >
                        <span className="nav-text">Solicitudes a mi cargo</span>
                      </NavLink>
                    </li>
                  )}

                  {procedureUnitHolder && (
                    <li>
                      <NavLink
                        className="prepend-icon"
                        activeClassName="selected"
                        to="/app/procedures/procedures-in-my-charge/validate-approve"
                      >
                        <span className="nav-text">
                          Validación de solicitudes
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {presentProceduresInMyCharge && (
                    <li>
                      <NavLink
                        className="prepend-icon"
                        activeClassName="selected"
                        to="/app/procedures/procedures-to-authorize"
                      >
                        <span className="nav-text">Solicitudes por autorizar</span>
                      </NavLink>
                    </li>
                  )}
                  {presentFollowToProcedureOption && (
                    <li>
                      <NavLink
                        className="prepend-icon"
                        activeClassName="selected"
                        to="/app/procedures/work-groups"
                      >
                        <span className="nav-text">Sala de seguimiento</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </li>

              {/* {
                presentAdminOptions && (
                  <li className="menu no-arrow">
                    <NavLink
                      className="prepend-icon sidenav__Option"
                      to="/app/help-content"
                    >
                      <i className="zmdi icon-5-Mi-muro_Admin-tutoriales zmdi-hc-fw" />
                      <span className="nav-text customColor">Administrar tutoriales</span>
                    </NavLink>
                  </li>
                )
              } */}

              {/* START::Administración  */}
              {/* ///////////////////// */}
              {/* // ||
                // user?.rol?.includes(RECORD_ADMINISTRATOR_ROLE) ||
                // user?.rol?.includes(RESPONSIBLE_ROLE) )  */}
              {(user?.rol?.includes(USER_MANAGEMENT_ROLE) )
                && (
                  <li
                    className={`ui_tooltip menu sidenav__Multi ${admin ? "selected open" : ""
                      }`}
                  >
                    <Button onClick={() => this.goToView("/app/administration")}>
                      <i className="zmdi icon-6-Administracion_Mi-muro zmdi-hc-fw" />
                      <span className="nav-text customColor">Administración</span>
                    </Button>

                    <ul className="sub-menu">
                      {presentUserOptions && user?.rol?.includes(USER_MANAGEMENT_ROLE) && (
                        <li>
                          <NavLink
                            className="prepend-icon"
                            activeClassName="selected"
                            to="/app/users"
                          >
                            <span className="nav-text">Usuarios</span>
                          </NavLink>
                        </li>
                      )}
                      {presentAdminForRecords && presentUserOptions && (
                        <li>
                          <NavLink
                            className="prepend-icon"
                            activeClassName="selected"
                            to="/app/users/admin-for-records"
                          >
                            <span className="nav-text">Visualizador de mi expediente</span>
                          </NavLink>
                        </li>
                      )}
                      {presentAdminOptions && (
                        <li>
                          <NavLink
                            className="prepend-icon"
                            activeClassName="selected"
                            to="/app/help-content"
                          >
                            <span className="nav-text">Tutoriales</span>
                          </NavLink>
                        </li>
                      )}
                      {presentAdminOptions && (
                          <li>
                            <NavLink
                              className="prepend-icon"
                              activeClassName="selected"
                              to="/app/day-absence"
                            >
                              <span className="nav-text">Días inhábiles</span>
                            </NavLink>
                          </li>
                        )}
                        {presentAdminOptions && (
                          <li>
                            <NavLink
                              className="prepend-icon"
                              activeClassName="selected"
                              to="/app/administration/categories"
                            >
                              <span className="nav-text">Categorías</span>
                            </NavLink>
                          </li>
                        )}
                    </ul>
                  </li>
                )}

              {/* END::ADMIN */}

              {/* START::NOTIFICACIONES */}

              <li className="menu no-arrow">
                <NavLink
                  className="prepend-icon sidenav__Option"
                  to="/app/notifications"
                >
                  <i className="zmdi zmdi-notifications zmdi-hc-fw" />
                  <span className="nav-text customColor">
                    Mis notificaciones
                  </span>
                </NavLink>
              </li>

              {/* END::NOTIFICACIONES */}

              {/* START::ENCUESTAS */}
              {(user?.rol?.includes(GENERAL_ADMIN_ROLE) ||
                user?.rol?.includes(RECORD_ADMINISTRATOR_ROLE) ||
                user?.rol?.includes(RESPONSIBLE_ROLE) ||
                level4Profiles) && (
                  <li
                    className={`ui_tooltip menu sidenav__Multi ${surveys ? "selected open" : ""
                      }`}
                  >
                    <Button onClick={() => this.goToView("/app/surveys")}>
                      <i className="zmdi icon-2-Mi-muro_Encuestas zmdi-hc-fw" />
                      <span className="nav-text customColor">Encuestas</span>
                    </Button>

                    <ul className="sub-menu">
                      {user?.rol?.includes(GENERAL_ADMIN_ROLE) && (
                        <li>
                          <NavLink
                            className="prepend-icon"
                            activeClassName="selected"
                            to="/app/surveys/social-media"
                          >
                            <span className="nav-text">Encuestas rápidas red social</span>
                          </NavLink>
                        </li>
                      )}
                      {user?.rol?.includes(GENERAL_ADMIN_ROLE) && (
                        <li>
                          <NavLink
                            className="prepend-icon"
                            activeClassName="selected"
                            to="/app/surveys/announcements"
                          >
                            <span className="nav-text">Convocatorias</span>
                          </NavLink>
                        </li>
                      )}
                      {(user?.rol?.includes(RECORD_ADMINISTRATOR_ROLE) ||
                        level4Profiles) && (
                          <li>
                            <NavLink
                              className="prepend-icon"
                              activeClassName="selected"
                              to="/app/surveys/records"
                            >
                              <span className="nav-text">Mi expediente</span>
                            </NavLink>
                          </li>
                        )}
                      {(user?.rol?.includes(GENERAL_ADMIN_ROLE) ||
                        user?.rol?.includes(RESPONSIBLE_ROLE) ||
                        user?.rol?.includes(OPERATIVE_PROCEDURE_RESPONSIBLE_ROLE) ||
                        level4Profiles) && (
                          <li>
                            <NavLink
                              className="prepend-icon"
                              activeClassName="selected"
                              to="/app/surveys/procedures"
                            >
                              <span className="nav-text">Mis trámites</span>
                            </NavLink>
                          </li>
                        )}
                    </ul>
                  </li>
                )}

              {/* END::ENCUESTAS */}

              {/* {presentUserOptions && (
                <li
                  className={`ui_tooltip menu sidenav__Multi ${
                    users ? "selected open" : ""
                  }`}
                >
                  <Button onClick={() => {
                    if (this.state.user?.rol?.includes(USER_MANAGEMENT_ROLE)) {
                      this.goToView("/app/users");
                    }
                  }}>
                    <i className="zmdi zmdi-account-box zmdi-hc-fw" />
                    <span className="nav-text customColor">Usuarios</span>
                  </Button>

                  <ul className="sub-menu">
                    {presentAdminForRecords && (
                      <li>
                        <NavLink
                          className="prepend-icon"
                          activeClassName="selected"
                          to="/app/users/admin-for-records"
                        >
                          <span className="nav-text">
                            Visualizador de mi expediente
                          </span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )} */}

              {/*START::DAY ABSENCE*/}
              {/* {presentAdminOptions && (
                <li className="menu no-arrow">
                  <NavLink
                    className="prepend-icon sidenav__Option"
                    to="/app/day-absence"
                  >
                    <i className="zmdi zmdi-calendar-close zmdi-hc-fw" />
                    <span className="nav-text customColor">Días inhábiles</span>
                  </NavLink>
                </li>
              )} */}
              {/*END::ADMIN*/}
            </ul>
          </div>
          <div className="col-12 text-center align-self-end mt-5">
            <img
              src={require("assets/images/secadmon_logo_gobierno_jal.png")}
              alt="Jalisco"
              title="Jalisco"
              width="45%"
              style={{ objectFit: "contain" }}
            />
            <p className="policy-privacy-login">
              <b>
                <a
                  href="https://portalsej.jalisco.gob.mx/home/aviso-de-privacidad-2/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Aviso de privacidad
                </a>
              </b>
            </p>
            <div className="row text-center"></div>
          </div>
          <div className="col-12 text-center align-self-end mt-5">
            <Directory />
          </div>
        </div>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
